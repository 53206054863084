
import { Modal } from "bootstrap";
import { defineComponent, inject, ref, watchEffect } from "vue";
import { Select } from "@/components/input-elements";
import InnerLoader from "@/components/InnerLoader.vue";
import { Apollo, Notify } from "@/core/services";
import { GET_CATEGORIES_DROPDOWN_LIST } from "@/modules/catalog/categories/graphql/Queries";
import { useI18n } from "vue-i18n";
import { ShopFooterEditFormData } from "@/modules/system/shop-footer/interfaces";
import { UPDATE_SHOP_FOOTER } from "@/modules/system/shop-footer/graphql/Mutations";

export default defineComponent({
	name: "shop footer modal",
	components: {
		Select,
		InnerLoader,
	},
	setup() {
		const loader = ref(false);
		const systemLocale = ref();
		const modal: any = ref();
		const categoriesList = ref([]) as Record<any, any>;
		const topCategories = ref([]) as Record<any, any>;
		const i18n = useI18n();
		const emitter: any = inject("emitter");
		const editShopFooterForm = ref<null | HTMLFormElement>(null);
		const editShopFooterData = ref<ShopFooterEditFormData>({
			id: 0,
			categories: [],
			status: false,
		});

		const submitShopFooterForm = () => {
			editShopFooterForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						id: editShopFooterData.value.id,
						category_ids: editShopFooterData.value.categories,
						status: editShopFooterData.value.status,
					};
					loader.value = true;
					await Apollo.mutate({
						mutation: UPDATE_SHOP_FOOTER,
						variables: {
							input: formData,
						},
						update: (store, { data: { create_shop_footer } }) => {
							loader.value = false;
							modal.value.hide();
							emitter.emit("reloadFooter");
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					});
				}
			});
		};

		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		const getCategories = () => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_CATEGORIES_DROPDOWN_LIST,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
			}).subscribe(({ data }) => {
				categoriesList.value = [];
				data.categories?.data.forEach((ele) => {
					categoriesList.value.push({
						label: JSON.parse(ele.name)[`${systemLocale.value}`].name,
						value: ele.id,
					});
				});
				loader.value = false;
			});
		};

		emitter.on("editShopFooterAction", (shop_footer) => {
			getCategories();
			editShopFooterForm.value?.resetFields();
			editShopFooterData.value.id = shop_footer.id;
			editShopFooterData.value.categories = JSON.parse(shop_footer.category_ids);
			editShopFooterData.value.status = shop_footer.status;
			modal.value = new Modal(document.getElementById("modal_edit_shop_footer"));
			modal.value.show();
		});

		return {
			loader,
			categoriesList,
			topCategories,
			editShopFooterData,
			editShopFooterForm,
			submitShopFooterForm,
		};
	},
});
