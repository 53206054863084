import { gql } from "graphql-tag";

export const SHOP_FOOTER_FIELDS = gql`
    fragment ShopFooterFields on ShopFooter {
        id
        category_ids
        categories
        status
    }
`;

export default { SHOP_FOOTER_FIELDS };
