import { gql } from "graphql-tag";
import { SHOP_FOOTER_FIELDS } from "./Fragments";

export const CREATE_SHOP_FOOTER = gql`
	mutation CreateShopFooter($input: ShopFooterInput!) {
		create_shop_footer(shop_footer: $input) {
			...ShopFooterFields
		}
	}
	${SHOP_FOOTER_FIELDS}
`;

export const UPDATE_SHOP_FOOTER = gql`
	mutation UpdateShopFooter($input: ShopFooterInput!) {
		update_shop_footer(shop_footer: $input) {
			...ShopFooterFields
		}
	}
	${SHOP_FOOTER_FIELDS}
`;

export const DELETE_SHOP_FOOTER = gql`
	mutation DeleteShopFooter($id: Int!) {
		delete_shop_footer(id: $id)
	}
`;

export default { CREATE_SHOP_FOOTER, DELETE_SHOP_FOOTER, UPDATE_SHOP_FOOTER };
