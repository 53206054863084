
import { defineComponent, ref, onMounted, inject } from "vue";
import { Apollo, Notify } from "@/core/services";
import TableContentLoader from "@/components/Table/TableContentLoader.vue";
import { GET_SHOP_FOOTER } from "@/modules/system/shop-footer/graphql/Queries";
import { DELETE_SHOP_FOOTER } from "@/modules/system/shop-footer/graphql/Mutations";
import Table from "@/components/Table/Table.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "shop-footer",
	components: { Table, TableContentLoader },
	setup() {
		const loader = ref(false);
		const shopFooter = ref([]);
		const emitter: any = inject("emitter");
		const activeIndex = ref(-1);
		const columns = ref([
			{
				label: "message.CATEGORIES",
				key: "categories",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);
		const store = useStore();
		const i18n = useI18n();

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};
		const getShopFooterData = async () => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_SHOP_FOOTER,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				errorPolicy: "all",
			}).subscribe(({ data, error }) => {
				if (error) {
					loader.value = false;
				}
				loader.value = false;
				shopFooter.value = data?.shop_footer;
			});
		};

		const deleteShopFooter = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_SHOP_FOOTER,
						variables: { id: id },
						update: (store, { data: { delete_shop_footer } }) => {
							const read_data = store.readQuery({
								query: GET_SHOP_FOOTER,
							}) as Record<any, any>;
							const data = read_data.shop_footer.filter((t: any) => t.id !== delete_shop_footer);
							store.writeQuery({
								query: GET_SHOP_FOOTER,
								data: {
									shop_footer: [...data],
								},
							});
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		const editShopFooter = (shop_footer: Record<any, any>) => {
			emitter.emit("editShopFooterAction", shop_footer);
		};

		emitter.on("reloadFooter", () => {
			getShopFooterData();
		});

		onMounted(() => {
			getShopFooterData();
			document.addEventListener("click", handleClickOutside);
		});
		return {
			loader,
			shopFooter,
			columns,
			getShopFooterData,
			editShopFooter,
			deleteShopFooter,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
