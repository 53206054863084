import {gql} from "graphql-tag";
import {SHOP_FOOTER_FIELDS} from "./Fragments";

export const GET_SHOP_FOOTER = gql`
    query GetShopFooter {
        shop_footer {
            ...ShopFooterFields
        }
    }
    ${SHOP_FOOTER_FIELDS}
`;

