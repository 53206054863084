
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import ShopFooterData from "../components/ShopFooterData.vue";
import AddShopFooter from "../components/AddShopFooter.vue";
import EditShopFooter from "../components/EditShopFooter.vue";
export default defineComponent({
  name: "shop-footer",
  components: {
    ShopFooterData,
    AddShopFooter,
    EditShopFooter
  },
  setup() {
    onMounted(() => {
      setPageHeader({
        title: "message.SHOP_FOOTER message.LIST",
        actionButton: {
          ability: "add_shop_footer",
          pageAction: {
            action: "addShopFooterAction",
          },
          button: {
            title: `message.ADD message.SHOP_FOOTER`,
            icon: "bi bi-plus",
            openModal: true,
          }
        },
        breadCrumbs: [
          { name: "message.HOME", link: "/dashboard"},
          { name: "message.SYSTEM", link: "#"},
          { name: "message.SETTINGS", link: "/system/settings"},
          { name: "message.SHOP_FOOTER message.LIST"}
        ]
      });
    });
  },
});
